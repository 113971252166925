@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.image {
  width: 100%;
  height: auto;
  border: 2px solid $primary;
}

.title {
  display: block;
  width: 100%;
  background: $backgroundPrimary;
  padding: $base $base * 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $base;
  color: #fff;
}

.icon {
  width: $base * 3;
  height: $base * 2;
}

.highlight {
  .image {
    border: 2px solid $secondary;
  }
  .title {
    background: $backgroundSecondary;
  }
}
